.main-content {
  height: calc(100vh - 120px) !important;
}
.map-content {
  height: calc(100vh - 140px) !important;
}
.home-content {
  height: calc(100vh - 125px) !important;
}
.safari-content {
  height: calc(100vh - 330px) !important;
}
/* General responsiveness (default) */
@media only screen and (max-width: 768px) {
  .home-content {
    height: calc(100vh - 250px) !important;
  }
}
/* For laptops (screen width up to 1200px) */
@media only screen and (max-width: 1200px) {
  .project-content {
    height: calc(100vh - 130px) !important;
  }
}

/* For large laptop screens (screen width up to 1440px) */
@media only screen and (min-width: 1201px) and (max-width: 1440px) {
  .project-content {
    height: calc(100vh - 100px) !important;
  }
}
/* For larger screens (screen width up to 1920px) */
@media only screen and (min-width: 1441px) and (max-width: 1920px) {
  .project-content {
    height: calc(100vh - 20px) !important;
  }
}

/* For iPads (screen width up to 1024px) */
@media only screen and (max-width: 1024px) {
  .project-content {
    height: calc(100vh - 50px) !important;
  }
}

/* General responsiveness (default) */
@media only screen and (max-width: 768px) {
  .project-content {
    height: calc(100vh - 300px) !important;
  }
}
.respoHome-content {
  height: calc(100vh - 300px) !important;
}
.controlpanel-content {
  height: calc(100vh - 500px) !important;
  max-height: calc(100vh - 500px) !important;
}
.public-content {
  height: calc(100vh - 72px) !important;
}
.responsive-content {
  height: calc(100vh - 290px) !important;
}

.dashboard-content {
  height: calc(100vh) !important;
}
.view-content {
  height: calc(100vh - 90px) !important;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.custom-loader {
  animation: none !important;
  border-width: 0 !important;
}
.swal2-container {
  z-index: 20000 !important;
}
.swal2-select {
  border: 1px solid black !important;
  border-radius: 10px !important;
}
.swal2-popup {
  font-size: 0.7rem !important;
  font-family: Georgia, serif;
}
.swal2-select option:checked,
.swal2-select option:hover {
  box-shadow: 0 0 10px 100px #000 inset !important;
}
.swal2-select:focus > option:checked {
  background: #000 !important;
}
#map {
  width: 100vw;
  height: 100vh;
}
#map-container {
  height: 180px;
  width: 100%;
}

/* #map {
  position: relative;
  height: inherit;
  width: inherit;
}
#map-container {
  width: "200px";
  height: "200px";
  z-index: 1000;
} */
.map-container {
  height: 100vh;
  width: 80vw;
}

.control-panel {
  position: absolute;
  top: 20;
  right: 0;
  max-width: 320px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 12px 24px;
  margin: 20px;
  font-size: 13px;
  line-height: 2;
  color: #6b6b76;
  text-transform: uppercase;
  outline: none;
}
.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crime-marker {
  background: none;
  border: none;
}

.crime-marker img {
  width: 25px;
}

.react-datepicker {
  z-index: 1000 !important;
  font-size: 14px !important;
  color: rgb(97, 97, 97) !important;
  border: 1px solid rgb(224 224 224 / var(--tw-border-opacity)) !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
.tooltip {
  pointer-events: none;
  position: absolute;
  z-index: 9;
  font-size: 12px;
  padding: 8px;
  background: #000;
  color: #fff;
  min-width: 160px;
  max-height: 240px;
  overflow-y: hidden;
}
.tooltip.interactive {
  pointer-events: all;
  background: #fcfcfc;
  color: #444;
  overflow-y: auto;
}
/* option:hover {
  background-color: yellow!important;
}
.mapbox-gl-draw_point {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="500" height="500">%3Cpath d="m10 2c-3.3 0-6 2.7-6 6s6 9 6 9 6-5.7 6-9-2.7-6-6-6zm0 2c2.1 0 3.8 1.7 3.8 3.8 0 1.5-1.8 3.9-2.9 5.2h-1.7c-1.1-1.4-2.9-3.8-2.9-5.2-.1-2.1 1.6-3.8 3.7-3.8z"/>%3C/svg>');
  background-repeat: no-repeat;
}
.mapbox-gl-draw_polygon {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20">%3Cpath d="m15 12.3v-4.6c.6-.3 1-1 1-1.7 0-1.1-.9-2-2-2-.7 0-1.4.4-1.7 1h-4.6c-.3-.6-1-1-1.7-1-1.1 0-2 .9-2 2 0 .7.4 1.4 1 1.7v4.6c-.6.3-1 1-1 1.7 0 1.1.9 2 2 2 .7 0 1.4-.4 1.7-1h4.6c.3.6 1 1 1.7 1 1.1 0 2-.9 2-2 0-.7-.4-1.4-1-1.7zm-8-.3v-4l1-1h4l1 1v4l-1 1h-4z"/>%3C/svg>');
}
.mapbox-gl-draw_line {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20">%3Cpath d="m13.5 3.5c-1.4 0-2.5 1.1-2.5 2.5 0 .3 0 .6.2.9l-3.8 3.8c-.3-.1-.6-.2-.9-.2-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5c0-.3 0-.6-.2-.9l3.8-3.8c.3.1.6.2.9.2 1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5z"/>%3C/svg>');
}
.mapbox-gl-draw_trash {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20">%3Cpath d="M10,3.4 c-0.8,0-1.5,0.5-1.8,1.2H5l-1,1v1h12v-1l-1-1h-3.2C11.5,3.9,10.8,3.4,10,3.4z M5,8v7c0,1,1,2,2,2h6c1,0,2-1,2-2V8h-2v5.5h-1.5V8h-3 v5.5H7V8H5z"/>%3C/svg>');
}
.mapbox-gl-draw_uncombine {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20">%3Cpath d="m12 2c-.3 0-.5.1-.7.3l-1 1c-.4.4-.4 1 0 1.4l1 1c.4.4 1 .4 1.4 0l1-1c.4-.4.4-1 0-1.4l-1-1c-.2-.2-.4-.3-.7-.3zm4 4c-.3 0-.5.1-.7.3l-1 1c-.4.4-.4 1 0 1.4l1 1c.4.4 1 .4 1.4 0l1-1c.4-.4.4-1 0-1.4l-1-1c-.2-.2-.4-.3-.7-.3zm-7 1c-1 0-1 1-.5 1.5.3.3 1 1 1 1l-1 1s-.5.5 0 1 1 0 1 0l1-1 1 1c.5.5 1.5.5 1.5-.5v-4zm-5 3c-.3 0-.5.1-.7.3l-1 1c-.4.4-.4 1 0 1.4l4.9 4.9c.4.4 1 .4 1.4 0l1-1c.4-.4.4-1 0-1.4l-4.9-4.9c-.1-.2-.4-.3-.7-.3z"/>%3C/svg>');
}
.mapbox-gl-draw_combine {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20">%3Cpath d="M12.1,2c-0.3,0-0.5,0.1-0.7,0.3l-1,1c-0.4,0.4-0.4,1,0,1.4l4.9,4.9c0.4,0.4,1,0.4,1.4,0l1-1 c0.4-0.4,0.4-1,0-1.4l-4.9-4.9C12.6,2.1,12.3,2,12.1,2z M8,8C7,8,7,9,7.5,9.5c0.3,0.3,1,1,1,1l-1,1c0,0-0.5,0.5,0,1s1,0,1,0l1-1l1,1 C11,13,12,13,12,12V8H8z M4,10c-0.3,0-0.5,0.1-0.7,0.3l-1,1c-0.4,0.4-0.4,1,0,1.4l1,1c0.4,0.4,1,0.4,1.4,0l1-1c0.4-0.4,0.4-1,0-1.4 l-1-1C4.5,10.1,4.3,10,4,10z M8,14c-0.3,0-0.5,0.1-0.7,0.3l-1,1c-0.4,0.4-0.4,1,0,1.4l1,1c0.4,0.4,1,0.4,1.4,0l1-1 c0.4-0.4,0.4-1,0-1.4l-1-1C8.5,14.1,8.3,14,8,14z"/>%3C/svg>');
} */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */

  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.example-custom-input {
  background: url("/src/assets/Images/Calendar.svg") no-repeat right 5px top 8px !important;
  background-size: 20px !important;
  width: 100% !important;
  height: 24px !important;
  border: 1px solid rgb(225, 224, 224) !important;
  padding: 18px 8px !important;
  border-radius: 5px !important;
}
:hover.example-custom-input {
  cursor: pointer;
}

.example-custom-input:focus-visible {
  border-color: #40514e !important;
  outline: none !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #576d69 !important;
  border-radius: 5px !important;
  box-shadow: inset 0 0 0 2px white !important;
  border: 1px solid #40514e !important;
  margin: 0.066rem !important;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #40514e !important;
  border-radius: 10px !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 10px !important;
}
.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crime-marker {
  background: none;
  border: none;
}

.crime-marker img {
  width: 25px;
}

.project-scroller {
  scroll-margin-top: 1200px;
}

.mapboxgl-ctrl-zoom-in {
  z-index: 9999 !important;
}
.image-grid {
  --gap: 5px;
  --num-cols: 4;
  --row-height: 150px;

  box-sizing: border-box;
  padding: var(--gap);

  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  grid-auto-rows: var(--row-height);
  gap: var(--gap);
}

.image-grid > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-grid-col-2 {
  grid-column: span 2;
}

.image-grid-row-2 {
  grid-row: span 2;
}

/* Anything udner 1024px */
@media screen and (max-width: 1024px) {
  .image-grid {
    --num-cols: 2;
    --row-height: 500px;
  }
}
.mapboxgl-popup-close-button {
  display: none !important;
}
.mapboxgl-popup-content {
  padding: 0 !important;
  border-bottom: none !important;
  box-shadow: none !important;
}

/* .mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: #0B3DC5!important;
} */
.mapboxgl-ctrl-top-right {
  z-index: 5 !important;
}
.mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
  position: relative;
}

.mapboxgl-ctrl-geocoder input[type="text"] {
  width: 100%;
  padding-right: 30px; /* Space for the dropdown icon */
}

.mapboxgl-ctrl-geocoder .suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px; /* Adjust as needed */
  overflow-y: scroll !important;
}

.mapboxgl-ctrl-geocoder .suggestions li {
  padding: 5px;
  cursor: pointer;
}

.mapboxgl-ctrl-geocoder .suggestions li:hover {
  background-color: #f0f0f0;
}
.dzu-inputLabel{
  color: black!important;
}
.dzu-inputLabelWithFiles{
  color: black!important;
}
.dzu-previewContainer {
  padding: 1%!important  ;
  display: flex!important;
  flex-direction: row!important;
  align-items: center!important;
  justify-content: space-between!important;
  position: relative!important;
  width: 100%!important;
  /* min-height: 60px; */
  z-index: 1!important;
  border-bottom: 1px solid #ECECEC!important;
  box-sizing: border-box!important;
}
.dzu-previewFileName {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.dzu-previewButton {
  background-size: 14px 14px;
  background-position: center;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  cursor: pointer;
  opacity: 0.9;
  margin: 0 0 5px 10px;
}